import galite from 'ga-lite';
import Clipboard from 'clipboard';
import * as $ from 'jquery';
import 'jquery-knob';

import './icons';
import './../css/reset.css';
import './../css/font.css';
import './../css/style.scss';

////////////////////////////////////////////////////
// Google Analytics ////////////////////////////////
////////////////////////////////////////////////////

galite.UA = 'UA-79689050-1';

////////////////////////////////////////////////////
// Error Messages //////////////////////////////////
////////////////////////////////////////////////////

function showMessage() {
  $('body').css('min-height', '527px');
  // $('#index #container, #helpText').css('margin-top', '-100px');
  $('#errMessage').show();
}

function hideMessage() {
  $('body').css('min-height', '470px');
  // $('#index #container, #helpText').css('margin-top', '-124px');
  $('#errMessage').hide();
}

$(document).ready(function () {
  var langCode = $('body').attr('data-lang');

  ////////////////////////////////////////////////////
  // Error Message ///////////////////////////////////
  ////////////////////////////////////////////////////

  if ($('#errMessage .content').text() != '') {
    showMessage();
  }

  ////////////////////////////////////////////////////
  // Index ///////////////////////////////////////////
  ////////////////////////////////////////////////////

  $('#choose').click(function (event) {
    hideMessage();

    $('#buttons').hide(0);

    $('#enterEmail').show(0, function () {
      $('#enterEmail input').val('').focus();
    });
  });

  $('#choose-form').on('submit', function (event) {
    if ($('#enterDomain:hidden').length) {
      $('#next').trigger('click');
      event.preventDefault();
      return false;
    }
  });

  $('#next').click(function (event) {
    var name = $('#enterEmail input').val();

    // The name filed is empty
    if (name === '') {
      return false;
    }

    $('#enterDomain select option').prop('selected', false);
    $('#enterDomain select option:eq(0)').prop('selected', true);

    $('#enterDomain select option').each(function (index, elem) {
      elem = $(elem);
      elem.text(name + '@' + elem.attr('value'));
    });

    $('#enterEmail').hide(0, function () {
      $('#enterDomain').show(0, function () {
        $('#enterDomain select').focus();
      });
    });
  });

  $('#create').click(function (event) {
    $('form').submit();
  });

  ////////////////////////////////////////////////////
  // Index > Languages ///////////////////////////////
  ////////////////////////////////////////////////////

  $('#langaugesMenu').on('click', function () {
    $(this).toggleClass('active');
  });

  ////////////////////////////////////////////////////
  // Index > Help ////////////////////////////////////
  ////////////////////////////////////////////////////

  $('#help h2').hover(
    function () {
      var index = $(this).index();
      $('#index #container').addClass('hidden');
      $('#helpText div').eq(index).removeClass('hidden');
      $('#helpText').removeClass('hidden');
    },
    function () {
      var index = $(this).index();
      $('#index #container').removeClass('hidden');
      $('#helpText div').eq(index).addClass('hidden');
      $('#helpText').addClass('hidden');
    }
  );

  ////////////////////////////////////////////////////
  // Inbox ///////////////////////////////////////////
  ////////////////////////////////////////////////////

  if ($('#inbox').length) {
    ////////////////////////////////////////////////////
    // Inbox > Timer ///////////////////////////////////
    ////////////////////////////////////////////////////

    if (parseInt($('#timeSec').val()) == 0) {
      $('#timeSec').val(59).trigger('change');
    }

    $('#timeMin').knob({
      max: 45,
      min: 0,
      readOnly: true,
      fgColor: '#D42849',
      bgColor: '#3D3D3D',
      inputColor: 'white',
      rotation: 'anticlockwise',
      width: '176',
      displayInput: false,
      thickness: 0.12,
    });

    $('#timeSec').knob({
      max: 59,
      min: 0,
      readOnly: true,
      fgColor: '#fff',
      bgColor: '#353534',
      inputColor: 'white',
      rotation: 'anticlockwise',
      width: '146',
      displayInput: false,
      thickness: 0.12,
    });

    function visualizeTime() {
      var min = $('#timeMin').val().length == 1 ? '0' + $('#timeMin').val() : $('#timeMin').val();
      var sec = $('#timeSec').val().length == 1 ? '0' + $('#timeSec').val() : $('#timeSec').val();

      $('#time .timeLabel').text(min + ':' + sec);
    }

    var timeTimer = setInterval(function () {
      visualizeTime();

      // Time finish
      if (parseInt($('#timeMin').val()) == 0 && parseInt($('#timeSec').val()) == 0) {
        clearInterval(timeTimer);

        $('#inboxBarCount #refresh').hide();

        setTimeout(function () {
          window.location.href = '/' + langCode;
        }, 1000);

        return;
      }

      if (parseInt($('#timeSec').val()) == 0) {
        $('#timeMin')
          .val(parseInt($('#timeMin').val()) - 1)
          .trigger('change');
        $('#timeSec').val(59).trigger('change');
      } else {
        $('#timeSec')
          .val(parseInt($('#timeSec').val()) - 1)
          .trigger('change');
      }
    }, 1000);

    $('#timeSec').trigger('change');
    $('#timeMin').trigger('change');

    visualizeTime();

    ////////////////////////////////////////////////////
    // Copy to Clipboard ///////////////////////////////
    ////////////////////////////////////////////////////

    var clipboard = new Clipboard('#email .email');

    clipboard.on('success', function (e) {
      var elem = $('#email .email');
      var address = elem.text();

      if (elem.text() === 'Copied') {
        return;
      }

      elem.text('Copied');

      setTimeout(function () {
        elem.text(address);
      }, 1000);
    });

    ////////////////////////////////////////////////////
    // Inbox > View Message ////////////////////////////
    ////////////////////////////////////////////////////

    $('#backBar .bar div').click(function () {
      $('#backBar, #read').hide();
      $('#inboxBarCount, #messages').show();
    });

    $('#read .body iframe').on('load', function () {
      if (typeof $(this).attr('src') == 'undefined' || !$(this).attr('src')) {
        return;
      }
      this.style.height = 0;
      $('#ajaxBar').hide(0, function () {
        $('#backBar, #read').fadeIn(function () {
          $('#read .body iframe').fadeIn(function () {
            this.style.height = this.contentWindow.document.body.scrollHeight + 'px';
          });
        });
      });
    });

    $(document).on('click', '#inbox tr[data-msg-id]', function () {
      var id = $(this).attr('data-msg-id');

      $(this).removeClass('unseen');
      $(this).addClass('seen');

      $('#read .header .subject').text($.trim($(this).find('.subject').text()));
      $('#read .header .sender .value').text($.trim($(this).find('.sender').text()));
      $('#read .header .time .value').text($.trim($(this).find('.time').text()));

      $('#inboxBarCount, #messages').hide(0, function () {
        $('#ajaxBar').show(0, function () {
          $('#read .body iframe').hide(0, function () {
            $('#read .body iframe').attr('src', '/' + langCode + '/message/' + id);
          });
        });
      });
    });
  }
});
