import * as $ from 'jquery';

import { library, icon } from '@fortawesome/fontawesome-svg-core';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons/faTrashCan';
import { faRefresh } from '@fortawesome/free-solid-svg-icons/faRefresh';
import { faPencil } from '@fortawesome/free-solid-svg-icons/faPencil';
import { faInbox } from '@fortawesome/free-solid-svg-icons/faInbox';
import { faLanguage } from '@fortawesome/free-solid-svg-icons/faLanguage';
import { faCirclePlay } from '@fortawesome/free-solid-svg-icons/faCirclePlay';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons/faCaretDown';
import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons/faChevronCircleRight';
import { faChevronCircleLeft } from '@fortawesome/free-solid-svg-icons/faChevronCircleLeft';
import { faCalendar } from '@fortawesome/free-solid-svg-icons/faCalendar';
import { faAddressBook } from '@fortawesome/free-solid-svg-icons/faAddressBook';

library.add(
  faTrashCan,
  faRefresh,
  faPencil,
  faInbox,
  faLanguage,
  faCirclePlay,
  faEnvelope,
  faCaretDown,
  faChevronCircleRight,
  faChevronCircleLeft,
  faCalendar,
  faAddressBook
);

$(document).ready(function () {
  $('i.fa-solid').each(function () {
    const elem = $(this);
    const classValue = elem.attr('class');
    const iconName = elem
      .clone()
      .removeClass('fa-solid')
      .attr('class')
      .match(/fa\-[^\s]+/)[0]
      .replace('fa-', '');

    const elemIcon = icon({ prefix: 'fa', iconName: iconName });

    if (elemIcon && elemIcon.html) {
      elem.replaceWith(elemIcon.html[0]);
    }
  });
});
